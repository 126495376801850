import React, { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Switch } from '@mui/material';

import { ModalContext } from '../context/ModalContext';
import { EditVariableModal } from '../pages/Variables/modals/EditVariableModal/EditVariableModal';
import { deleteAlertMessageVariables, updateVariables } from '../redux/variables/variablesActions';

import styles from '../pages/Variables/Variables.module.css';


export const VariablesSchema = (
  processFilter, 
  setProcessFilter,
  typeValue, 
  setTypeValue,
  valueFilter, 
  setValueFilter,
  brandNameFilter,
  setBrandNameFilter,
  inputFieldError,
  setInputFieldError,
) => {
  const dispatch = useDispatch();
  const { showModal } = useContext(ModalContext);

  const handleValidationInputs = ({ target: { value } }, nameField) => {
    const errorMessage = 'Must be more than 3 symbols';
    setInputFieldError((prev) => ((value.length < 3 && value.length > 0) ? { ...prev, errorMessage, [nameField]: true } : { ...prev, errorMessage: '', [nameField]: false }));
    if (nameField === 'processFilterIsValid') setProcessFilter(value);
    if (nameField === 'typeValueIsValid') setTypeValue(value);
    if (nameField === 'brandNameFilterIsValid') setBrandNameFilter(value);
  };

  const handleRunningAdsSelect = (checked, id, actions) => {
    dispatch(updateVariables({
      id,
      actions,
      value: checked, 
      variant: null,
    }));
  };


  const editAction = (id, brandName, action, value, variant) => {
    showModal({
      headerContent: <h3>Edit Variables</h3>,
      bodyContent: <EditVariableModal 
        brandName={brandName} 
        id={id} 
        variablesAction={action} 
        propsValue={value} 
        variant={variant} 
      />,
    });
  };

  const deleteAction = (id) => {
    dispatch(deleteAlertMessageVariables(id));
  };

  const valueOutput = (value, actions, managerNames, id) => {
    if (actions === 'Self User Registration Assign') {
      return (
        <>
          {managerNames?.slice(0, 2).map((name) => (
            <p key={name} className={styles.brandNameText}>{name}</p>
          ))}
          {managerNames?.length > 2 && (
          <p className={styles.brandNameText}>
            {`and ${managerNames.length - 2} more...`}
          </p>
          )}
        </>
      );
    }

    if (actions === 'Running Advertisement') {
      return (
        <Switch checked={value} color="success" onChange={(_, checked) => handleRunningAdsSelect(checked, id, actions)} />
      );
    }

    return (
      <p className={styles.brandNameText}>
        {value.length > 24 ? `${value.slice(0, 24)}...` : value}
      </p>
    );
  };

  const columns = [
    {
      name: (
        <div className="d-flex align-items-center flex-column search-input-des p-2 pl-0">
          Process
          <input
            className="leads-search-bar-filter"
            style={inputFieldError.processFilterIsValid ? { border: '1px solid red' } : { border: 'none' }}
            type="text"
            placeholder="Search"
            autoComplete="off"
            name="Actions"
            value={processFilter || ''}
            onChange={(e) => handleValidationInputs(e, 'processFilterIsValid')}
          />
          <span style={{ height: '20px' }}>
            {inputFieldError.processFilterIsValid && (
              <p className={styles.errorInput}>
                {inputFieldError.errorMessage}
              </p>
            )}
          </span> 
        </div>),
      minWidth: '220px',
      cell: ({ actions }) => actions,
    },
    {
      name: (
        <div className="d-flex align-items-center flex-column search-input-des p-2 pl-0">
          Type
          <input
            className="leads-search-bar-filter"
            style={inputFieldError.typeValueIsValid ? { border: '1px solid red' } : { border: 'none' }}
            type="text"
            placeholder="Search"
            autoComplete="off"
            name="Type"
            value={typeValue || ''}
            onChange={(e) => handleValidationInputs(e, 'typeValueIsValid')}
          />
          <span style={{ height: '20px' }}>
            {inputFieldError.typeValueIsValid && (
              <p className={styles.errorInput}>
                {inputFieldError.errorMessage}
              </p>
            )}
          </span> 
        </div>),
      minWidth: '200px',
      cell: ({ type }) => (type === 'managerIds' ? 'Manager Id\'s' : type),
    },
    {
      name: (
        <div className="d-flex align-items-center flex-column search-input-des p-2 pl-0">
          Value
          <input
            className="leads-search-bar-filter"
            type="text"
            placeholder="Search"
            autoComplete="off"
            name="Value"
            value={valueFilter || ''}
            onChange={({ target: { value } }) => setValueFilter(value)}
          />
          <span style={{ height: '20px' }} /> 
        </div>),
      minWidth: '200px',
      cell: ({
        value, actions, managerNames, _id, 
      }) => (
        <div className={styles.brandNameWrapper}>
          { valueOutput(value, actions, managerNames, _id) }
        </div>
      ),
    },
    {
      name: (
        <div className="d-flex align-items-center flex-column search-input-des p-2 pl-0">
          Brand Name
          <input
            className="leads-search-bar-filter"
            style={inputFieldError.brandNameFilterIsValid ? { border: '1px solid red' } : { border: 'none' }}
            type="text"
            placeholder="Search"
            autoComplete="off"
            name="Brand Name"
            value={brandNameFilter || ''}
            onChange={(e) => handleValidationInputs(e, 'brandNameFilterIsValid')}
          />
          <span style={{ height: '20px' }}>
            {inputFieldError.brandNameFilterIsValid && (
              <p className={styles.errorInput}>
                {inputFieldError.errorMessage}
              </p>
            )}
          </span> 
        </div>),
      minWidth: '200px',
      cell: ({ brandName }) => {
        if (brandName?.length > 1) return 'All brands';
        if (brandName?.length === 0) return '-';
        return (
          <div className={styles.brandNameWrapper}>
            {brandName?.map((name) => (
              <p key={name} className={styles.brandNameText}>{name}</p>
            ))}
          </div>
        );
      },
    },
    {
      name: 'Variant',
      minWidth: '200px',
      cell: ({ variant }) => (variant ? `${variant.charAt(0).toUpperCase()}${variant.slice(1)}` : '-'),
    },
    {
      name: 'Actions',
      minWidth: '200px',
      cell: ({
        brandName, _id, actions, value, variant,
      }) => (
        <>
          {
            actions !== 'Running Advertisement' && (
              <button 
                type="button"  
                className="btn btn-success btn-sm me-1 p-1" 
                onClick={() => editAction(_id, brandName, actions, value, variant)}
              >
                <FontAwesomeIcon icon={faPencil} />
              </button>
            )
          }

          {brandName.length <= 1 && (
            <button 
              type="button" 
              className="btn btn-danger btn-sm me-1 p-1"
              onClick={() => deleteAction(_id)}
            >
              <FontAwesomeIcon icon={faTrash} />
            </button>
          ) }
        </>
      ),
    },
  ];

  return columns;
};

