import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { formatDate } from './helper';
import { ModalFilters } from '../components';

export const ClientActivityColumns = (
  userFilters, 
  setUserFilters,
  crmUsersFilter, 
  setCrmUsersFilter,
  activityTypeFilter,
  setActivityTypeFilter,
  clientNameFilter,
  setClientNameFilter,
) => {
  const permissionName = useSelector((state) => state.crmUser?.currentUserPermissions);
  const crmUsers = useSelector((state) => state.crmUser?.crmUsers);
  const activityTypes = useSelector((state) => state?.activityClientType?.activityClientTypes);

  const clientActivityColumns = [
    {
      name: 'Time',
      selector: (row) => (
        <span>{formatDate(new Date(row?.createdAt))}</span>
      ),
      sortable: true,
    },
    {
      name: (<ModalFilters 
        data={crmUsers} 
        filters={crmUsersFilter} 
        setFilters={setCrmUsersFilter} 
        tabName="CRM User" 
        searchParams={{ id: '_id', params: ['firstName', 'lastName'], optionsName: ['firstName', 'lastName'] }}
        usersFilters={userFilters}
        setUserFilters={setUserFilters}
      />),
      selector: (row) => {
        if (row.crmUser) return `${row?.crmUser?.firstName} ${row?.crmUser.lastName}`;
        return '-';
      },
      cell: (row) => {
        if (
          row.crmUser
        ) {
          return (
            permissionName && permissionName.length > 0 && permissionName.includes('edit_crm_user')
              ? (
                <Link
                  to={`/edit-admin/${row.crmUser._id}`}
                  className="text-decoration-none"
                >
                  <span className="full-name-text">{`${row?.crmUser?.firstName} ${row?.crmUser?.lastName}`}</span>
                </Link>
              )
              : <span>{`${row.crmUser?.firstName} ${row?.crmUser?.lastName}`}</span>
          );
        }
        return '-';
      },
    },
    {
      name: (
        <div className="d-flex flex-column search-input-des p-4 pl-0" style={{ alignItems: 'start' }}>
          Client
          <input
            className="leads-search-bar-filter"
            type="text"
            placeholder="Search"
            autoComplete="off"
            name="clientFullname"
            value={clientNameFilter}
            minLength="3"
            onChange={(e) => setClientNameFilter(e.target.value)}
          />
          {
            (!!clientNameFilter && clientNameFilter.length >= 1 && clientNameFilter.length < 3) 
            && (<span className="datatable-input__error-text clr-red">Please enter at least 3 characters</span>)
          }
        </div>
      ),
      selector: (row) => {
        if (row?.client?._id) return `${row?.client?.firstName} ${row?.client?.lastName}`;
        return '-';
      },
      cell: (row) => {
        if (
          row?.userId
        ) {
          return (
            permissionName && permissionName.length && permissionName.includes('edit_user')
              ? (
                <Link
                  to={`/user-detail/${row?.client?._id}`}
                  className="text-decoration-none"
                >
                  <span className="full-name-text">
                    {`${row?.client?.firstName} ${row?.client?.lastName}`}
                  </span>
                </Link>
              )
              : <span>{`${row?.client?.firstName} ${row?.client?.lastName}`}</span>
          );
        }
        return '-';
      },
      sortable: false,
    },
    {
      name: 
      (<ModalFilters 
        data={activityTypes} 
        filters={activityTypeFilter} 
        setFilters={setActivityTypeFilter} 
        tabName="Activity" 
        searchParams={{ id: '_id', params: ['name'], optionsName: ['name'] }}
        usersFilters={userFilters}
        setUserFilters={setUserFilters}
      />),
      minWidth: '190px',
      selector: (row) => (row?.activityType ? row?.activityType?.name : ''),
      cell: (row) => (
        <span>
          {row?.activityType?.name}
          {' '}
        </span>
      ),
    },
    {
      name: 'Information',
      selector: (row) => row?.information,
      cell: (row) => (
        <span>{row?.information}</span>
      ),
      sortable: true,
    },
  ];
  return clientActivityColumns;
};
