import React from 'react';
import { Link } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClone, faExternalLink, faTrash } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import { toFixed } from '../../helpers/utils';

import { RangeModalDebounceFilter } from '../../pages/Transactions/modalFilters';
import { ModalFilters } from '../../components';
import { omitColumn, formatDate, dateOptions } from '../helper';

import './style.css';

export const CfdAccountsSchema = (
  columnConfig,
  amountRange,
  setAmountRange,
  cfdQuery,
  setCfdQuery,
  currencies,
  assetFilter,
  setAssetFilter,
  creditRange,
  setCreditRange,
  permissionName,
  balanceRange,
  setBalanceRange,
  setDebounceAmountRange,
  customIdFilter, 
  setCustomIdFilter,
  fullNameFilter, 
  setFullNameFilter,
  handleDeleteCfdAccounts,
  setInputFieldError,
  inputFieldError,
  accountTypes,
  crmUsers,
  assignedToFilter,
  setAssignedToFilter,
  salesStatuses,
  statusFilter,
  setStatusFilter,
  createdAtFilter,
  setCreatedAtFilter,
  orderData,
) => {
  const isUserCanSeeCfdAccounts = permissionName.includes('cfd_accounts');
  const copyReferral = () => {
    toast.success('Successfully copied!', {
      autoClose: 1000,
    });
  };

  const handleValidationInputs = ({ target: { value } }, nameField) => {
    if (value.length < 3 && value !== '') setInputFieldError((prev) => ({ ...prev, errorMessage: 'Must be more than 3 symbols', [nameField]: true }));
    else setInputFieldError((prev) => ({ ...prev, errorMessage: '', [nameField]: false }));
    if (nameField === 'fullNameIsNotValid') setFullNameFilter(value);
  };

  const calcMargin = (orders = []) => {
    if (!orders.length) return 0;
    let margin = 0;

    // eslint-disable-next-line no-restricted-syntax
    for (const order of orders) {
      if (Object.keys(orderData).includes(order._id)) {
        margin += Number(orderData[order._id].margin);
      }
    }

    return toFixed(margin, 2);
  };

  const calcPnL = (orders = []) => {
    if (!orders.length) return 0;
    let pnl = 0;

    // eslint-disable-next-line no-restricted-syntax
    for (const order of orders) {
      if (Object.keys(orderData).includes(order._id)) {
        pnl += Number(orderData[order._id].pnl);
      }
    }

    return toFixed(pnl, 2);
  };
  
  const columns = [
    {
      name: (
        <div className="cfdAccount_idWrapper">
          <p className="cfdAccount_idWrapper_text">ID</p>
          <input 
            type="text"
            placeholder="Search"
            autoComplete="off"
            className="cfdAccount_input"
            style={inputFieldError.transactionIdIsNotValid ? { border: '1px solid red' } : { border: 'none' }}
            name="id"
            value={customIdFilter || ''}
            onChange={(e) => setCustomIdFilter(e.target.value)}
          />
          <span className="cfdAccount_errorInputWrapper" />
        </div>),
      width: '200px',
      omit: omitColumn(columnConfig, 'ID'), 
      cell: ({ customId }) => (
        <CopyToClipboard text={customId}>
          <span className="cfdSccounts-row_Id">
            <FontAwesomeIcon icon={faClone} onClick={() => copyReferral()} className="me-2" />
            { customId }
          </span>
        </CopyToClipboard>
      ),
    },
    {
      name: (
        <div className="cfdAccount_idWrapper">
          <p className="cfdAccount_idWrapper_text">Client</p>
          <input 
            type="text"
            placeholder="Search"
            autoComplete="off"
            name="client"
            className="cfdAccount_input"
            value={fullNameFilter || ''}
            onChange={(e) => handleValidationInputs(e, 'fullNameIsNotValid')}
          />
          <span className="cfdAccount_errorInputWrapper">
            {inputFieldError.fullNameIsNotValid && (
              <p className="cfdAccount_errorInput">
                {inputFieldError.errorMessage}
              </p>
            )}
          </span> 
        </div>),
      width: '200px',
      omit: omitColumn(columnConfig, 'Client'), 
      cell: ({ user, userId }) => ( 
        <div className="cfdSccounts-row_ClientWrapper">
          { 
            (user && user.firstName && user.lastName) ? (
              <Link to={`/user-detail/${userId}`} target="blank" className="text-white">
                <FontAwesomeIcon icon={faExternalLink} size="xs" />
                <span 
                  className="cfdSccounts-row_fullName"
                >
                  {`${user.firstName} ${user.lastName}`}
                </span>
              </Link>
            ) : (<span>-</span>) 
          }
        </div> 
      ), 
    },
    {
      name: (
        <ModalFilters 
          data={crmUsers} 
          filters={assignedToFilter} 
          setFilters={setAssignedToFilter} 
          tabName="Assigned To" 
          searchParams={{ id: '_id', params: ['firstName', 'lastName'], optionsName: ['firstName', 'lastName'] }}
          usersFilters={cfdQuery}
          setUserFilters={setCfdQuery}
        />
      ),
      omit: omitColumn(columnConfig, 'Assigned To'), 
      minWidth: '200px',
      cell: ({ user }) => (
        <div className="cfdSccounts-row_ClientWrapper">
          { 
          (user && user.manager) ? (
            <Link to={`/edit-admin/${user.manager._id}`} target="blank" className="text-white">
              <FontAwesomeIcon icon={faExternalLink} size="xs" />
              <span 
                className="cfdSccounts-row_fullName"
              >
                {`${user.manager.firstName} ${user.manager.lastName}`}
              </span>
            </Link>
          ) : (<span>-</span>) 
        }
        </div> 
      ),
    },
    {
      name: (
        <ModalFilters 
          data={salesStatuses} 
          filters={statusFilter} 
          setFilters={setStatusFilter} 
          tabName="Status" 
          searchParams={{ id: '_id', params: ['name'], optionsName: ['name'] }}
          usersFilters={cfdQuery}
          setUserFilters={setCfdQuery}
        />
      ),
      omit: omitColumn(columnConfig, 'Status'), 
      minWidth: '200px',
      cell: ({ user }) => (user && user.status ? user.status.name : '-'),
    },
    {
      name: (
        <ModalFilters 
          data={accountTypes} 
          filters={assetFilter} 
          setFilters={setAssetFilter} 
          tabName="Type" 
          searchParams={{ id: '_id', params: ['name'], optionsName: ['name'] }}
          usersFilters={cfdQuery}
          setUserFilters={setCfdQuery}
        />
      ),
      omit: omitColumn(columnConfig, 'Type'), 
      minWidth: '200px',
      cell: ({ type }) => (type?.name),
    },
    {
      name: (
        <RangeModalDebounceFilter
          columnName="Total Amount" 
          value={amountRange ?? [0, 1000000]} 
          setValue={setAmountRange} 
          setFilters={setDebounceAmountRange} 
          filters={{}} 
          filedName="amountRange"
          debounceAmountRange
        />
      ),
      minWidth: '200px',
      omit: omitColumn(columnConfig, 'Amount'), 
      cell: ({ total }) => total?.toFixed(2),
    },
    {
      name: (
        <RangeModalDebounceFilter 
          columnName="Balance" 
          value={balanceRange ?? [0, 1000000]} 
          setValue={setBalanceRange} 
          setFilters={setDebounceAmountRange} 
          filters={{}} 
          filedName="balanceRange"
          debounceAmountRange
        />
      ),
      minWidth: '200px',
      omit: omitColumn(columnConfig, 'Balance'), 
      cell: ({ balance }) => balance.toFixed(2),
    },
    {
      name: (
        <ModalFilters 
          data={currencies} 
          filters={assetFilter} 
          setFilters={setAssetFilter} 
          tabName="Currency" 
          searchParams={{ id: '_id', params: ['name', '_id', 'symbol'], optionsName: ['symbol'] }}
          usersFilters={cfdQuery}
          setUserFilters={setCfdQuery}
        />
      ),
      omit: omitColumn(columnConfig, 'Currency'), 
      minWidth: '200px',
      cell: ({ cfdAccountCurrency, isSaving, currency }) => (!isSaving ? cfdAccountCurrency?.symbol : currency?.symbol),
    },
    {
      name: ( 
        <RangeModalDebounceFilter
          columnName="Credit" 
          value={creditRange ?? [0, 1000000]} 
          setValue={setCreditRange} 
          setFilters={setCfdQuery} 
          filters={cfdQuery}
          filedName="creditRange"
        />
      ),
      minWidth: '200px',
      omit: omitColumn(columnConfig, 'Credit'), 
      cell: ({ credit, cfdAccountCurrency, isSaving }) => (!isSaving ? `${credit?.toFixed(2)} ${cfdAccountCurrency?.symbol}` : '-'),
    },
    {
      name: 'Leverage',
      minWidth: '180px',
      selector: ({ leverage }) => (leverage || '-'), 
      omit: omitColumn(columnConfig, 'Leverage'), 
      sortable: true,
    },
    {
      name: 'Lot Step',
      minWidth: '180px',
      selector: ({ lotStep }) => (lotStep || '-'), 
      omit: omitColumn(columnConfig, 'Lot Step'), 
      sortable: true,
    },
    {
      name: 'Lot Size',
      minWidth: '180px',
      selector: ({ lotSize }) => (lotSize || '-'),
      omit: omitColumn(columnConfig, 'Lot Size'), 
      sortable: true,
    },
    {
      name: 'Commission%',
      minWidth: '180px',
      selector: ({ commission }) => (commission || '-'), 
      omit: omitColumn(columnConfig, 'Commission'), 
      sortable: true,
    },
    {
      name: 'Swap Long%',
      minWidth: '180px',
      selector: ({ swapLong }) => (swapLong || '-'), 
      omit: omitColumn(columnConfig, 'Swap Long'), 
      sortable: true,
    },
    {
      name: 'Swap Short%',
      minWidth: '180px',
      selector: ({ swapShort }) => (swapShort || '-'), 
      omit: omitColumn(columnConfig, 'Swap Short'), 
      sortable: true,
    },
    {
      name: 'Margin',
      minWidth: '180px',
      selector: ({ orders }) => {
        const margin = calcMargin(orders);

        return (<span style={margin !== 0 ? { color: margin > 0 ? 'green' : 'red' } : {}}>{margin}</span>);
      }, 
      omit: omitColumn(columnConfig, 'Margin'), 
    },
    {
      name: 'Margin Level',
      minWidth: '180px',
      selector: ({ orders, balance, credit }) => {
        const margin = calcMargin(orders);
        const pnl = calcPnL(orders);
        const equity = Number(balance) + pnl + Number(credit);
        const marginLevel = margin !== 0 ? (equity / margin) * 100 : 0;

        return (<span style={marginLevel !== 0 ? { color: marginLevel > 0 ? 'green' : 'red' } : {}}>{`${toFixed(marginLevel, 2)} %`}</span>);
      }, 
      omit: omitColumn(columnConfig, 'Margin Level'), 
    },
    {
      name: 'Free Margin',
      minWidth: '180px',
      selector: ({ orders, balance, credit }) => {
        const margin = calcMargin(orders);
        const pnl = calcPnL(orders);
        const equity = Number(balance) + pnl + Number(credit);
        const freeMargin = equity - margin;

        return (<span style={freeMargin !== 0 ? { color: freeMargin > 0 ? 'green' : 'red' } : {}}>{toFixed(freeMargin, 2)}</span>);
      }, 
      omit: omitColumn(columnConfig, 'Free Margin'), 
    },
    {
      name: (
        <ModalFilters 
          onClick={(e) => e.stopPropagation()}
          data={dateOptions} 
          filters={createdAtFilter} 
          setFilters={setCreatedAtFilter} 
          tabName="Created" 
          searchParams={{ id: '_id', params: ['name'], optionsName: ['name'] }}
          usersFilters={cfdQuery}
          setUserFilters={setCfdQuery}
          isDateOptions
        />
      ),
      width: '200px',
      omit: omitColumn(columnConfig, 'Created'),
      selector: ({ createdAt }) => (
        <span>{formatDate(new Date(createdAt))}</span>
      ),
    },
    {
      name: 'Action(s)',
      minWidth: '200px',
      omit: omitColumn(columnConfig, 'Actions'), 
      cell: (row) => (isUserCanSeeCfdAccounts ? (
        <button
          type="button"
          className="btn btn-danger btn-sm me-1 p-1"
          onClick={() => handleDeleteCfdAccounts(row)}
        >
          <FontAwesomeIcon icon={faTrash} className="header-icon" />
        </button>
      ) : (<span>-</span>)),
    },
  ];
  return columns;
};
