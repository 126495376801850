import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { formatDate } from './helper';
import { ModalFilters } from '../components';
import { selectAllCountries } from '../redux/countries/countriesSelectors';

export const CRMActivityColumns = (
  userFilters, 
  setUserFilters,
  crmUsersFilter,
  setCrmUsersFilter,
  crmActivityTypeFilter, 
  setCrmActivityTypeFilter,
  clientNameFilter,
  setClientNameFilter,
) => {
  const permissionName = useSelector((state) => state.crmUser?.currentUserPermissions);
  const crmUsers = useSelector((state) => state.crmUser.crmUsers);
  const activityTypes = useSelector((state) => state.activityType.activityTypes);
  const countries = useSelector(selectAllCountries);

  const makeListOfRecords = ({ state = [], ids = [], nameField = '' }) => {
    let list = '';

    // eslint-disable-next-line no-restricted-syntax
    for (const record of state) {
      if (ids.includes(record._id)) {
        if (list.length < 25) {
          list += `${record[nameField]}, `;
        } else {
          break;
        }
      }
    }

    list = list.length ? `${list.slice(0, -2)}...and ${list.length - 2} more` : list;

    return list;
  };

  const makeListOfCountries = (text) => {
    const cutOffIndex = Math.min(
      text.indexOf('to') !== -1 ? text.indexOf('to') : text.length,
      text.indexOf('from') !== -1 ? text.indexOf('from') : text.length,
    );
    if (cutOffIndex !== text.length) {
      const keyword = text.slice(cutOffIndex, cutOffIndex + 4).trim();
      const firstTextPart = text.slice(0, cutOffIndex + keyword.length);

      const substring = text.slice(cutOffIndex + keyword.length).trim();
      const ids = substring.split(',').map((id) => id.trim()).filter((id) => id);
      const prettyCountriesOutput = makeListOfRecords({ state: countries, ids, nameField: 'nicename' });

      return `${firstTextPart}: ${prettyCountriesOutput}`;
    }
    return text;
  };

  const checkPsp = (row) => {
    const regex = /(includedCountries|excludedCountries)/;
    if (row?.activityType?.name === 'PSP Update') {
      if (regex.test(row?.information)) {
        return makeListOfCountries(row?.information);
      }
    };
    return row?.information;
  };

  const crmActivityColumns = [
    {
      name: 'Time',
      selector: (row) => (
        <span>
          {formatDate(new Date(row?.createdAt))}
        </span>
      ),
      sortable: true,
    },
    {
      name: 
       (<ModalFilters 
         data={crmUsers} 
         filters={crmUsersFilter} 
         setFilters={setCrmUsersFilter} 
         tabName="CRM User" 
         searchParams={{ id: '_id', params: ['firstName', 'lastName'], optionsName: ['firstName', 'lastName'] }}
         usersFilters={userFilters}
         setUserFilters={setUserFilters}
       />),
      selector: (row) => {
        if (row?.crmUser?._id) {
          return `${row?.crmUser?.firstName} ${row?.crmUser?.lastName}`;
        }
        return '-';
      },
      cell: (row) => {
        if (row?.crmUserId) {
          return permissionName
            && permissionName.length > 0
            && permissionName.includes('edit_crm_user') ? (
              <Link
                to={`/edit-admin/${row?.crmUserId}`}
                className="text-decoration-none"
              >
                <span className="full-name-text"> 
                  {`${row?.crmUser?.firstName} ${row?.crmUser?.lastName}`}
                </span>
              </Link>
            ) : (
              <span>{`${row?.crmUser?.firstName} ${row?.crmUser?.lastName}`}</span>
            );
        }
        return '-';
      },
    },
    {
      name: (
        <div className="d-flex flex-column search-input-des p-4 pl-0" style={{ alignItems: 'start' }}>
          Client
          <input
            className="leads-search-bar-filter"
            type="text"
            placeholder="Search"
            autoComplete="off"
            name="clientFullname"
            value={clientNameFilter}
            minLength="3"
            onChange={(e) => setClientNameFilter(e.target.value)}
          />
          {
            (!!clientNameFilter && clientNameFilter.length >= 1 && clientNameFilter.length < 3) 
            && (<span className="datatable-input__error-text clr-red">Please enter at least 3 characters</span>)
          }
        </div>
      ),
      selector: (row) => {
        if (row?.client?._id) {
          return `${row?.client?.firstName} ${row?.client?.lastName}`;
        }
        return '-';
      },
      cell: (row) => {
        if (row?.userId) {
          if (row?.client) {
            return permissionName
            && permissionName.length > 0
            && permissionName.includes('edit_user') ? (
              <Link
                to={`/user-detail/${row?.client?._id}`}
                className="text-decoration-none"
              >
                <span
                  className="full-name-text"
                >
                  {`${row?.client?.firstName} ${row?.client?.lastName}`}
                </span>
              </Link>
              ) : (
                <span>{`${row?.client?.firstName} ${row?.client?.lastName}`}</span>
              );
          }
          return 'User Not Found';
        }
        return '-';
      },
      sortable: false,
    },
    {
      name: 
      (<ModalFilters 
        data={activityTypes} 
        filters={crmActivityTypeFilter} 
        setFilters={setCrmActivityTypeFilter} 
        tabName="Activity" 
        searchParams={{ id: '_id', params: ['name'], optionsName: ['name'] }}
        usersFilters={userFilters}
        setUserFilters={setUserFilters}
      />),
      minWidth: '190px',
      selector: (row) => (row?.activityType ? row?.activityType?.name : ''),
      cell: (row) => <span>{row?.activityType?.name}</span>,
    },
    {
      name: 'Information',
      selector: (row) => row?.information,
      cell: (row) => <span>{checkPsp(row)}</span>,
      sortable: true,
    },
  ];
  return crmActivityColumns;
};
